import { useEffect, useState } from 'react'
import OrderLineItemComponent from './OrderLineItem/OrderLineItem'
import { Pagination } from 'swiper/modules'
import { getCustomerOrders } from '@/clients/shopify/client'
import { Swiper, SwiperSlide } from 'swiper/react'
import { variantIsSubscription } from '@/utilities/commerce'

import { useStore } from '@nanostores/react'
import { loadAddons } from '@/stores/addon/operations'
import { $addonSkus } from '@/stores/addon/computed'
import type { OrderLineItem } from '@/types/shopify'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/pagination'
import './RecentPurchases.scss'

const ORDERS_PER_PAGE = 200
const DISPLAYED_PER_PAGE = 3

const RecentPurchases = () => {
  const addonSkus = useStore($addonSkus)

  const [allPurchases, setAllPurchases] = useState<OrderLineItem[]>([])
  const [filteredPurchases, setFilteredPurchases] = useState<OrderLineItem[]>(
    [],
  )
  const [displayedPurchases, setDisplayedPurchases] = useState<OrderLineItem[]>(
    [],
  )

  const fetchOrders = async () => {
    try {
      const result = await getCustomerOrders(ORDERS_PER_PAGE)
      const lineItems = result.edges.flatMap((order) =>
        order.node.lineItems.edges.map((edge) => ({
          ...edge.node,
          statusUrl: order.node.statusUrl,
        })),
      )
      setAllPurchases(lineItems)
    } catch (error) {
      console.error('Error fetching orders', error)
    }
  }

  useEffect(() => {
    fetchOrders()
    loadAddons()
  }, [])

  useEffect(() => {
    // If item is not a subscription, not an add-on, and has a price greater than 0
    const result = allPurchases.filter(
      (lineItem) =>
        !variantIsSubscription(lineItem?.variant?.sku) &&
        !addonSkus.includes(lineItem?.variant?.sku) &&
        parseFloat(lineItem?.variant?.price.amount) > 0,
    )

    setFilteredPurchases(result)
    setDisplayedPurchases(result.slice(0, DISPLAYED_PER_PAGE))
  }, [addonSkus, allPurchases])

  if (!displayedPurchases?.length) return null

  return (
    <div className="c-recent-purchases">
      <h2 className="c-recent-purchases__header type-label">
        Recent Purchases
      </h2>
      <div className="c-recent-purchases__list">
        <div className="c-recent-purchases__list-wrapper">
          {displayedPurchases.map((orderLineItem, index) => {
            return (
              <div key={index} className="c-recent-purchases__item">
                <OrderLineItemComponent orderLineItem={orderLineItem} />
              </div>
            )
          })}
        </div>
        {filteredPurchases.length > DISPLAYED_PER_PAGE && (
          <a
            href="/account?tab_id=order-history"
            className="c-recent-purchases__load button button--primary o-loadable o-loadable--small o-loadable--inverted"
          >
            Order history
          </a>
        )}
      </div>
      <Swiper
        className="swiper-container"
        modules={[Pagination]}
        spaceBetween={32}
        slidesPerView={1}
        pagination={{
          el: '#c-recent-purchases__swiper-pagination',
          clickable: true,
        }}
      >
        {displayedPurchases.map((orderLineItem, index) => {
          return (
            <SwiperSlide key={index} className="swiper-slide">
              <OrderLineItemComponent orderLineItem={orderLineItem} />
            </SwiperSlide>
          )
        })}
        {filteredPurchases.length > DISPLAYED_PER_PAGE && (
          <SwiperSlide
            key="order-history"
            className="swiper-slide swiper-slide-end"
          >
            <a
              href={'/account?tab_id=order-history'}
              className="c-customer-subscriptions__load-more button button--primary o-loadable o-loadable--small o-loadable--inverted"
            >
              Order history
            </a>
          </SwiperSlide>
        )}
      </Swiper>
      <div
        id="c-recent-purchases__swiper-pagination"
        className="swiper-pagination"
      />
    </div>
  )
}

export default RecentPurchases
